::-webkit-scrollbar-track {background-color: #e9edf0;border-left: 1px solid #ededed;}
::-webkit-scrollbar {width: 7px;background-color: #606063}
::-webkit-scrollbar-thumb {background:#606063}
body {
    font-family: "Calibri" !important;
    font-size: 15px;
    line-height: 18px;
    color: #555;
    position: relative;
    font-weight: normal;
    background-color: #fff;
}
main{
    margin: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.content-wrapper {
    flex-grow: 1;
    display: flex;

}
footer {
    background: #263238;
    color: #fff;
    background-position: center;
    background-size: cover;
    flex-shrink: 0 !important;
}
.bebas{
    font-family: 'Bebas Neue';
}
.stretch-card {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: stretch;
    align-items: stretch;
    -webkit-justify-content: stretch;
    justify-content: stretch;
}
.text-red{
    color: #ee3338;
}
.text-gray{
    color: #606063;
}
.text-dark{
    color: #373535;
}
.bg-red{
    background-color: #ee3338;
    color: #fff !important;
}
.bg-gray{
    background-color: #606063;
    color: #fff !important;
}
.bg-dark{
    background-color: #373535;
    color: #fff !important;
}
.b-radius{
    border-radius: 25px !important;
}
.b-shadow{
    box-shadow: 15px 15px 35px rgb(0 0 0 / 20%);
}
a.navbar-brand img{
    width: 170px;
}
svg.quotes {
    position: absolute;
    top: -29px;
    right: -10px;
    font-size: 70px;
    z-index: 1;
    color: #ee3338;
}
.tariff .card{
    border:1px solid transparent;
    transform: scale(1.0);
    transition: all 150ms;
}
.tariff .card:hover{
    border:1px solid rgb(238 50 56 / 30%);
    box-shadow: 0px 0 1px rgb(238 50 56 / 0%);
    transform: scale(1.02);
    transition: all 150ms;
}

/* RESSET */
.tariff .lh {
    line-height: 14px;
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: 576px) {
    .container {
      max-width: 100%;
    }
  }

  @media (min-width: 768px) {
    .container {
      max-width: 100%;
    }
  }

  @media (min-width: 992px) {
    .container {
      max-width: 98%x;
    }
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 1280px;
    }
  }

.dark-green-text {
	color: #263238 !important;
}

.dark-green-bg {
    background: linear-gradient(-145deg, #ff0005, #ff4f8a 50%, #bd03ff) !important;
	animation: gradient 1s ease infinite !important;
	background-size: 400% 400%;
 }

.dark-green-shadow {
	box-shadow: 20px 20px 35px rgb(237 151 1 / 50%);
}

.dark-green-shadow:hover {
	box-shadow: 0 0 5px rgb(237 151 1);
}

.red-text {
	color: #ff8080 !important;
}

.red-bg {
	background: #ff8080;
}

.red-shadow {
	box-shadow: 20px 20px 35px rgb(255 128 128 / 50%);
}

.red-shadow:hover {
	box-shadow: 0 0 5px rgb(255 128 128 / 1);
}

.green-text {
	color: #4e9d29 !important;
}

.green-bg {
	background-image: linear-gradient(-145deg, #83ef4b, #3e871d, #8fd55d) !important;
	animation: gradient 10s ease infinite !important;
	background-size: 400% 400%;
}

.green-shadow {
	box-shadow: 20px 20px 35px rgb(78 188 27 / 50%);
}

.green-shadow:hover {
	box-shadow: 0 0 5px rgb(78 188 27);
}

.gradient-text {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background-size: cover;
    background-position: 50% !important;
    line-height: initial !important;
}

.gradient-green-bg {
	background-image: linear-gradient(-145deg, #83ef4b, #3e871d, #8fd55d) !important;
	animation: gradient 10s ease infinite !important;
	background-size: 400% 400%;
}

.gradient-orange-bg {
	background-image: linear-gradient(-145deg, #cf4901, #ffd100 50%, #ed7901) !important;
	animation: gradient 10s ease infinite !important;
	background-size: 400% 400%;
}
.accordion-button:not(.collapsed) {
    color: #F44336;
    background-color: #ffffff;
    box-shadow: none !important;
    border-bottom: 1px solid #F44336 !important;
}
.accordion-button:focus {
    z-index: 3;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none !important
}